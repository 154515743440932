import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS = {
    1: "https://mainnet.infura.io/v3/585d373ac52d4d60a49e5b6331c11810",
    5: "https://goerli.infura.io/v3/585d373ac52d4d60a49e5b6331c11810"
};

// CHANGE ALL TO:  1==MAINNET, 4==RINKEBY  (2 changes under WalletConnect!)

// METAMASK
export const injected = new InjectedConnector({
    supportedChainIds: [1]
});

// WALLET CONNECT
export const walletconnect = new WalletConnectConnector({
    rpc: { 1: RPC_URLS[1] },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: POLLING_INTERVAL
});

// COINBASE
export const walletlink = new WalletLinkConnector({
    url: RPC_URLS[1],
    appName: "web3-react-nft"
});